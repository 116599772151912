const blogFilters = [
  "DOCUMENTS",
  "SIGNATURE",
  "FAX",
  // "SHIP",
  // "PAYMENTS",
  // "FORMS",
  "SUITE",
  "OPERO",
]
const caseStudyFilters = [
  "DOCUMENTS",
  "SIGNATURE",
  "FAX",
  // "SHIP",
  // "PAYMENTS",
  // "FORMS",
  "SUITE",
  "OPERO",
]

export { blogFilters, caseStudyFilters }
