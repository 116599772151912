import React, { useEffect } from "react"
import styled from "styled-components"

import { Portal } from "react-portal"
import { motion, AnimatePresence } from "framer-motion"

import { Link } from "components/common"
import { resourcesNavLinks } from "utils"
// import AppAccordionNav from "../apps/AppAccordionNav"
import NavAccordion from "./NavAccordion"
import AppsAccordionNav from "../apps/AppsAccordionNav"

const NavAni = {
  exit: {
    opacity: 0,
  },
  from: {
    // opacity: 0,
    // y: -40,
  },
  to: {
    // opacity: 1,
    // y: 0,
    transition: {
      // when: "beforeChildren",
      staggerChildren: 0.05,
    },
  },
}

const linkAni = {
  from: {
    x: -40,
    opacity: 0,
  },
  to: {
    x: 0,
    opacity: 1,
  },
}

const MobileSideNav = ({ open, close, slug }) => {
  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto"
      close()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [open])

  return (
    <AnimatePresence>
      {open && (
        <Portal>
          <SideNav>
            <Navigation
              variants={NavAni}
              initial="from"
              animate="to"
              exit="exit"
            >
              <motion.li variants={linkAni}>
                <AppsAccordionNav />
                <Divider />
              </motion.li>

              <motion.li variants={linkAni}>
                <Link to="/about">
                  <h6>About</h6>
                </Link>
                <Divider />
              </motion.li>

              <motion.li variants={linkAni}>
                <NavAccordion title="Resources" links={resourcesNavLinks} />
                <Divider />
              </motion.li>
              <motion.li variants={linkAni}>
                <Link to="/contact">
                  <h6>CONTACT US</h6>
                </Link>
                <Divider />
              </motion.li>
            </Navigation>
          </SideNav>
        </Portal>
      )}
    </AnimatePresence>
  )
}

const SideNav = styled(motion.div)`
  position: fixed;
  /* top: 80px; */
  top: 0px;
  left: 0;
  right: 0;
  /* bottom: 0; */
  z-index: 9999;
  background: var(--card-shade_1);
  box-shadow: 0px 40x 48px 0px rgba(23, 23, 23, 0.5);
  /* important for scroll */
  padding-top: 80px;
  overflow: auto;
  height: 100%;
  z-index: 20;
`

const Navigation = styled(motion.ul)`
  display: grid;
  grid-template-columns: 1fr;

  a {
    display: block;
    padding: var(--sp-section-lr);

    &:hover {
      opacity: 0.8;
    }
  }
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: linear-gradient(90deg, #505156 84.86%, rgba(80, 81, 86, 0) 100%);
`

export default MobileSideNav
