import React from "react"
import styled from "styled-components"

import { TextGroup, BasicCard, Media } from "components/common"

import { device } from "utils"

const QuoteCard = ({ title, subtitle, icon }) => {
  return (
    <StyledQuoteCard>
      <BasicCard>
        <div className="iconWrap">
          <Media className="icon" media={icon} />
        </div>
        <TextGroup title={title} subtitle={subtitle} />
      </BasicCard>
    </StyledQuoteCard>
  )
}

const StyledQuoteCard = styled.div`
  position: relative;
  --card-padding: var(--sp-96) var(--sp-16) var(--sp-64);
  --tg-text-align: center;

  @media ${device.laptop} {
    --tg-text-align: left;
    --card-padding: var(--sp-80) var(--sp-120);
  }

  .iconWrap {
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
    @media ${device.laptop} {
      transform: translateX(0);

      top: var(--sp-32);
      left: -70px;
    }
    .icon {
      max-width: 140px;
      img {
        border-radius: 50%;
        box-shadow: var(--card-shadow);
      }
    }
  }

  .subtitle {
    white-space: break-spaces;
  }
`

export default QuoteCard
