import { useRef, useLayoutEffect, useState } from "react"

// https://dev.to/michaelburrows/calculate-the-estimated-reading-time-of-an-article-using-javascript-2k9l

const useReadingTime = () => {
  const ref = useRef()
  const [time, setTime] = useState("5")

  function readingTime() {
    const wpm = 225
    const text = ref.current.innerText
    const words = text.trim().split(/\s+/).length

    const time = Math.ceil(words / wpm)

    setTime(`${time} min read`)
  }

  useLayoutEffect(() => {
    readingTime()
  }, [ref])

  return [ref, time]
}

export default useReadingTime
