import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import classNames from "classnames"
import { HContainer, Link } from "components/common"
import { device, appTextColors, appTextFromSlug } from "utils"
import { AppsToolTip, ResourcesToolTip } from "components/navigation"

import OperoLogo from "src/images/nav/nav_opero_logo.inline.svg"

const textAni = {
  from: {
    opacity: 0,
    x: -40,
  },
  to: {
    opacity: 1,
    x: 0,
    transition: {
      // type: "linear",
      delay: 0.3,
    },
  },
}

const PrimaryNav = ({ slug }) => {
  return (
    <StyledPrimaryNav className="primary-nav">
      <HContainer>
        <LogoWrap>
          <Link to="/" className={classNames({ slug })}>
            <OperoLogo />
          </Link>
          {slug && (
            <motion.h5 variants={textAni} initial="from" animate="to">
              {appTextFromSlug[slug]}
              <strong className={slug}>.</strong>
            </motion.h5>
          )}
        </LogoWrap>
        <ul>
          <AppsToolTip />

          <li>
            <Link to="/about">About</Link>
          </li>

          <ResourcesToolTip />
        </ul>
      </HContainer>
    </StyledPrimaryNav>
  )
}

const StyledPrimaryNav = styled.header`
  background: var(--card-shade_1);
  position: relative;
  z-index: 20;

  display: none;
  @media ${device.laptop} {
    display: block;
  }

  .max {
    --section-align: center;
    padding: var(--sp-24) 0;
    flex-direction: row;
    justify-content: space-between;
  }

  ul {
    display: grid;
    justify-content: flex-end;
    grid-template-columns: repeat(3, auto);
    grid-gap: var(--sp-48);

    li,
    a {
      font-family: var(--body-font-family);
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 22px;
    }

    a {
      &:hover {
        opacity: 0.8;
      }
    }
  }
`

const LogoWrap = styled.div`
  display: flex;
  align-items: center;

  a {
    &.slug {
      border-right: 1px solid #53565d;
      padding-right: var(--sp-32);
      margin-right: var(--sp-32);
    }
  }

  h5 {
    line-height: 1;
    padding-bottom: 4px;
    strong {
      ${appTextColors};
    }
  }
`

export default PrimaryNav
