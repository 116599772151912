import React, { useState } from "react"
import styled from "styled-components"

import { device, appTextColors, appTextFromSlug } from "utils"

import { Link } from "components/common"

import MobileSideNav from "./MobileSideNav"

import AppAccordionNav from "src/components/navigation/apps/AppAccordionNav"

import MobileOperoLogo from "src/images/nav/opero_logo_mobile_nav.inline.svg"

import Hamburger from "src/images/nav/mobile_hamburger.inline.svg"
import Close from "src/images/nav/mobile_close.inline.svg"

const MobileNav = ({ slug }) => {
  const [open, setOpen] = useState(false)

  const toggle = () => {
    setOpen(!open)
  }

  return (
    <>
      <StyledMobileNav className="mobileNav">
        <Link to="/">
          <MobileOperoLogo className="mobile_logo" />
        </Link>
        {slug && (
          <div className="app">
            <h5>
              {appTextFromSlug[slug]}
              <strong className={slug}>.</strong>
            </h5>
          </div>
        )}
        <button className="hamburger" onClick={toggle}>
          {open ? <Close /> : <Hamburger />}
        </button>
      </StyledMobileNav>
      <Spacer />
      {slug && (
        <AppAWrap>
          <div className="mobile-nav-wrap">
            <AppAccordionNav slug={slug} />
          </div>
        </AppAWrap>
      )}

      <MobileSideNav open={open} close={toggle} slug={slug} />
    </>
  )
}

const StyledMobileNav = styled.header`
  padding: var(--sp-section-lr);
  justify-content: flex-start;
  background: var(--card-shade_1);
  display: flex;
  align-items: center;
  /* position: relative; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 30;

  @media ${device.laptop} {
    display: none;
  }

  h5 {
    font-size: 1.125rem;

    border-left: 1px solid #53565d;
    padding-left: var(--sp-8);
    margin-left: var(--sp-8);

    strong {
      ${appTextColors};
    }
  }

  .hamburger {
    margin-left: auto;
  }
`

const Spacer = styled.div`
  height: 77px;
  width: 100%;

  @media ${device.laptop} {
    display: none;
  }
`

const AppAWrap = styled.div`
  position: relative;
  height: 66px;
  width: 100%;
  z-index: 15;
  @media ${device.laptop} {
    display: none;
  }

  .mobile-nav-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 15;
  }
`

export default MobileNav
