const resourcesNavLinks = [
  {
    text: "Knowledge Base",
    to: "/knowledge",
  },
  {
    text: "Partners",
    to: "/partners",
  },
  {
    text: "Case Studies",
    to: "/case-study",
  },
  {
    text: "Blog",
    to: "/blog",
  },
]

export default resourcesNavLinks
